
import { Component, Prop, Vue } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { userService } from "@/services/api/user.service";
import { paymentsService } from "@/services/api/payments.service";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class SubscriptionPaln extends Vue {
  private downgrade = false;
  private subscriptionPlan: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private subscriptionExpired = false;

  @Prop({ type: String, default: "", required: false })
  private addTitle: string;

  @Prop({ type: Boolean, default: false, required: false })
  private cancelledPlan: boolean;

  private required = required;
  private isFormValid = false;
  private expirationDate = 0;

  async created() {
    overlayModule.showOverlay();
    this.downgrade = (await paymentsService.getChangedPlan()).data;
    this.subscriptionPlan = (await userService.GetSubscription()).data;
    var parse = this.subscriptionPlan.expiration.split("/");
    this.subscriptionExpired =
      new Date(parse[1] + "/" + parse[0] + "/" + parse[2]).getTime() <
      new Date().getTime();
  }
}
